import * as Sentry from "@sentry/sveltekit"
import { env } from "$env/dynamic/public"

const sentryEnabled = [
    "development",
    "development",
    "staging",
    "production",
].includes(env.PUBLIC_ENVIRONMENT as string)
const rate = env.PUBLIC_ENVIRONMENT === "staging" ? 0.5 : 0.25

Sentry.init({
    enabled: sentryEnabled,

    dsn: "https://3c9bd381178499241a7f722fd93a83f3@o4505988165926912.ingest.sentry.io/4505988167958528",
    release: "4f9814e6e52b4e14b495ea48ac580c40",
    environment: env.PUBLIC_ENVIRONMENT,
    sampleRate: 1.0,
    tracesSampleRate: rate,
    replaysSessionSampleRate: 0.25,
    replaysOnErrorSampleRate: 0.25,
    integrations: [
        // Sentry.feedbackIntegration({
        //     colorScheme: "system",
        //     isNameRequired: true,
        //     isEmailRequired: true,
        // }),
        // not needed anymore in sentry V8
        // Sentry.metrics.metricsAggregatorIntegration(),
    ],

    tracesSampler: (samplingContext) => {
        // Examine provided context data (including parent decision, if any) along
        // with anything in the global namespace to compute the sample rate or
        // sampling decision for this transaction

        // if ("...") {
        //   // These are important - take a big sample
        //   return 1;
        // } else if ("...") {
        //   // These are less important or happen much more frequently - only take 1%
        //   return 0.01;
        // } else if ("...") {
        //   // These aren't something worth tracking - drop all transactions like this
        //   return 0;
        // } else {
        //   // Default sample rate
        //   return 0.5;
        // }
        return rate
    },

    beforeBreadcrumb(breadcrumb, hint) {
        if (
            breadcrumb.category === "fetch" &&
            breadcrumb.level === "error" &&
            hint &&
            hint.input[0].startsWith("/api")
        ) {
            console.log(
                "Skipping breadcrumb:",
                breadcrumb.level,
                breadcrumb.category,
                hint.input[0],
            )
            return null
        }

        return breadcrumb
    },

    ignoreErrors: [
        "The specified key does not exist.",
        "Non-Error promise rejection captured with value: Aborting fetch requests",
        "AbortError: The user aborted a request.",
        "Fetch is aborted",
        "Load failed",
        "fetch failed",
        "Failed to fetch",
        "Non-Error exception captured",
        "Non-Error promise rejection",
        "ChunkLoadError",
        /^(?=.*?\bloading\b).*?\bchunk\b.*/,
    ],
})

/** @type {import('@sveltejs/kit').HandleClientError} */
export const myErrorHandler = async ({
    error,
    event,
    status,
}: {
    error: Error
    event: Event
    status: number
    message: string
}) => {
    const errorId = crypto.randomUUID()

    Sentry.captureException(error, {
        extra: { event, errorId, status },
    })

    return {
        message: "Client error occurred",
        errorId,
    }
}

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler)
